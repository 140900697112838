<template>
  <div class="help-content">
    <h2>Help Center</h2>
    <hr>
    <br>
    <p>If you have questions or need assistance, please contact our support team:</p>
    <ul>
      <li>Email: <a href="mailto:support@example.com" style="color:#073763;font-weight:600px;">support@example.com</a></li>
      <li>Phone: +1 123-456-7890</li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.help-content {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.help-content h2 {
  color: #073763;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}
.help-content ul {
  list-style: none;
  padding: 0;
}
.help-content li {
  margin-bottom: 10px;
}
</style>
