<template>
  <div>
    <div class="help-icon" @click="toggleHelpWindow">
      <InlineSvg :src="IconAskMathew" />
      <span class="tooltip">ASK MATTHEW</span>
    </div>
    <AskMathewBotWindow
      v-if="isHelpWindowOpen && isUserLoggedIn"
      :userProfile="userProfile"
      @close="toggleHelpWindow"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import IconAskMathew from '@/assets/svg/ask-mathew.svg'
import AskMathewBotWindow from '@/components/Global/AskMathewBotWindow.vue'
import { useUserProfile } from '@/api/use-user-profile.js'

export default {
  name: 'AskMathewLayout',
  components: {
    AskMathewBotWindow,
  },
  setup() {
    const store = useStore()
    const { isLoading, isError, data: userProfile } = useUserProfile()
    const isHelpWindowOpen = ref(false)
    const userId = store.state.globalComponents.userId

    // Computed property to check if the user is logged in (userId is not null)
    const isUserLoggedIn = computed(() => userId !== null)

    const toggleHelpWindow = () => {
      isHelpWindowOpen.value = !isHelpWindowOpen.value
    }

    return {
      IconAskMathew,
      isLoading,
      isError,
      userProfile,
      isHelpWindowOpen,
      toggleHelpWindow,
      isUserLoggedIn,
    }
  },
}
</script>

<style scoped>
.help-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-icon:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.tooltip {
  position: absolute;
  bottom: 75%; /* Position above the help icon */
  right: 75%;
  transform: translateX(50%);
  background-color: #073763;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
}
</style>
