<template>
  <div class="faq-content">
    <h2>Frequently Asked Questions</h2>
    <hr />
    <br />
    <ul>
      <li v-for="(faq, index) in faqs" :key="index">
        <span class="toggle-button" @click="toggleAnswer(index)">
          {{ faq.question }}
        </span>
        <p v-if="faq.showAnswer">
          {{ faq.answer }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          question: 'How do I reset my password?',
          answer: "Go to settings and click on 'Reset Password'.",
          showAnswer: false,
        },
        {
          question: 'Where can I find my purchase history?',
          answer: "Navigate to the 'Orders' section in your account.",
          showAnswer: false,
        },
        {
          question: 'How do I reset my password?',
          answer: "Go to settings and click on 'Reset Password'.",
          showAnswer: false,
        },
        {
          question: 'Where can I find my purchase history?',
          answer: "Navigate to the 'Orders' section in your account.",
          showAnswer: false,
        },
        {
          question: 'How do I reset my password?',
          answer: "Go to settings and click on 'Reset Password'.",
          showAnswer: false,
        },
        {
          question: 'Where can I find my purchase history?',
          answer: "Navigate to the 'Orders' section in your account.",
          showAnswer: false,
        },
      ],
    }
  },
  methods: {
    toggleAnswer(index) {
      this.faqs[index].showAnswer = !this.faqs[index].showAnswer
    },
  },
}
</script>

<style scoped>
.faq-content {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.faq-content h2 {
  color: #073763;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}
.faq-content ul {
  list-style: none;
  padding: 0;
}
.faq-content li {
  margin-bottom: 10px;
}
.faq-content ul li p {
  margin :5px;
}
.toggle-button {
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}

</style>
