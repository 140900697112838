import { createStore } from 'vuex'
import auth from './modules/auth'
import dashboard from './modules/dashboard'
import newProspect from './modules/newProspect'
import newClient from './modules/newClient'
import globalComponents from './modules/globalComponents'
import notifications from './modules/notifications'
import activity from './modules/activity'
import logs from './modules/logs'
import adminPanelUsers from './modules/adminPanelUsers'
import draft from './modules/draft'

import createPersistedState from 'vuex-persistedstate'

const dataOtpType = createPersistedState({
  paths: ['auth.otpType'],
  key: 'otpType',
  storage: window.sessionStorage,
})

const dataAwsConfig = createPersistedState({
  paths: ['globalComponents.awsConfig'],
  key: 'awsConfig',
  storage: window.sessionStorage,
})

const dataTRCM = createPersistedState({
  paths: ['globalComponents.TRCMData'],
  key: 'TRCMData',
  storage: window.sessionStorage,
})

const dataTRCMSecrets = createPersistedState({
  paths: ['globalComponents.TRCMSecrets'],
  key: 'TRCMSecrets',
  storage: window.sessionStorage,
})

const dataCalendarView = createPersistedState({
  paths: ['globalComponents.calendarView'],
  key: 'calendarView',
  storage: window.sessionStorage,
})

const dataStateAuth = createPersistedState({
  paths: ['auth.isAuth'],
  key: 'auth',
  storage: window.sessionStorage,
})

const dataStateAdvisorId = createPersistedState({
  paths: ['globalComponents.advisorId'],
  key: 'advisorId',
  storage: window.sessionStorage,
})

const dataSetMemberId = createPersistedState({
  paths: ['globalComponents.memberId'],
  key: 'memberId',
  storage: window.sessionStorage,
})

const dataStateMultipleAccount = createPersistedState({
  paths: ['globalComponents.multipleAccount'],
  key: 'multipleAccount',
  storage: window.sessionStorage,
})

const dataStateIsMultipleVerified = createPersistedState({
  paths: ['globalComponents.isMultipleVerified'],
  key: 'isMultipleVerified',
  storage: window.sessionStorage,
})

const dataStateSetclientId = createPersistedState({
  paths: ['globalComponents.clientId'],
  key: 'clientId',
  storage: window.sessionStorage,
})

const dataStateCeoId = createPersistedState({
  paths: ['globalComponents.ceoId'],
  key: 'ceoId',
  storage: window.sessionStorage,
})

const dataStateSuperAdminId = createPersistedState({
  paths: ['globalComponents.adminId'],
  key: 'adminId',
  storage: window.sessionStorage,
})

const dataStateRole = createPersistedState({
  paths: ['globalComponents.role'],
  key: 'role',
  storage: window.sessionStorage,
})

const dataStateCurrentCompanyId = createPersistedState({
  paths: ['globalComponents.currentCompanyId'],
  key: 'currentCompanyId',
  storage: window.sessionStorage,
})

const dataStateUserId = createPersistedState({
  paths: ['globalComponents.userId'],
  key: 'userId',
  storage: window.sessionStorage,
})

const dataStateClientId = createPersistedState({
  paths: ['globalComponents.clientId'],
  key: 'clientId',
  storage: window.sessionStorage,
})

const dataStateLeadId = createPersistedState({
  paths: ['globalComponents.leadId'],
  key: 'leadId',
  storage: window.sessionStorage,
})

const dataStateSupportId = createPersistedState({
  paths: ['globalComponents.supportId'],
  key: 'supportId',
  storage: window.sessionStorage,
})

const dataStateTerms = createPersistedState({
  paths: ['globalComponents.termsAndConditions'],
  key: 'termsAndConditions',
  storage: window.sessionStorage,
})

const dataStateTermsInfo = createPersistedState({
  paths: ['globalComponents.termsAndConditionsInfo'],
  key: 'termsAndConditionsInfo',
  storage: window.sessionStorage,
})

const dataStateTrcmULR = createPersistedState({
  paths: ['globalComponents.trcmURL'],
  key: 'trcmURL',
  storage: window.sessionStorage,
})

const dataDraft = createPersistedState({
  paths: ['draft'],
  key: 'draft',
  storage: window.sessionStorage,
})

const dataTotalContractValue = createPersistedState({
  paths: ['globalComponents.totalContractValue'],
  key: 'totalContractValue',
  storage: window.sessionStorage,
})

export default createStore({
  modules: {
    auth,
    dashboard,
    newProspect,
    newClient,
    globalComponents,
    notifications,
    activity,
    logs,
    adminPanelUsers,
    draft,
  },
  plugins: [
    dataStateAuth,
    dataTRCM,
    dataTRCMSecrets,
    dataSetMemberId,
    dataOtpType,
    dataStateAdvisorId,
    dataStateRole,
    dataStateIsMultipleVerified,
    dataStateCurrentCompanyId,
    dataCalendarView,
    dataStateUserId,
    dataStateSetclientId,
    dataStateMultipleAccount,
    dataStateCeoId,
    dataStateSuperAdminId,
    dataTotalContractValue,
    dataStateClientId,
    dataStateLeadId,
    dataStateTerms,
    dataStateTermsInfo,
    dataStateTrcmULR,
    dataStateSupportId,
    dataDraft,
    dataAwsConfig
  ],
})
