<template>
  <div class="chatbot-window">
    <!-- Close Button -->
    <button class="close-button" @click="$emit('close')">✖</button>

    <!-- Greeting Message -->
    <div class="welcome-message">
      <div class="avatar-and-greeting">
        <SwdAvatar class="avatar" />
        <div class="greeting-text" v-if="userProfile">
          <p>Hi, {{ userProfile.firstName }} {{ userProfile.lastName }}!</p>
          <p>How can I assist you today?</p>
        </div>
        <div v-else><p>Loading...</p></div>
      </div>
    </div>

    <!-- Tab Content Area -->
    <div class="tab-content">
      <FaqWindow v-if="selectedTab === 'FAQ'" />
      <HelpWindow v-if="selectedTab === 'HELP'" />
      <div v-if="selectedTab === 'ASK MATTHEW'" class="tab-panel">
        <div class="ask-mathew-content">
          <h2>Ask Matthew</h2>
          <hr />
          <br />
          <div class="chat-history">
            <div v-for="(msg, index) in chatHistory" :key="index" :class="['chat-bubble', msg.sender]">
              <button v-if="msg.sender === 'bot'" class="copy-button" @click="handleCopy(msg.text, index)">
                <InlineSvg :src="IconCopy" class="copyicon" />
                <span v-if="copiedIndex === index">Copied!</span>
                <span v-else>Copy</span>
              </button>

              <div v-if="msg.sender === 'bot'" v-html="formatMessage(msg.text)"></div>
              <div v-else>{{ msg.text }}</div>

              <!-- Display timestamp after each message -->
              <div class="timestamp">{{ msg.timestamp }}</div>

              <!-- Feedback Form -->
              <div v-if="msg.sender === 'bot' && msg.showFeedbackForm" class="feedback-form">
                <p>Thank you for the chat! Your review helps me grow!</p>
                <p>How would you rate your experience with our products?</p>
                <InlineSvg :src="IconLike" class="iconlike" />
                <p>How would you rate your experience with our products?</p>
                <div class="feedback-options">
                  <ul>
                    <li>
                      <label>
                        <input type="radio" :name="'feedback-' + index" value="Excellent" v-model="msg.feedback" />
                        Excellent
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="radio" :name="'feedback-' + index" value="Good" v-model="msg.feedback" />
                        Good
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="radio"
                          :name="'feedback-' + index"
                          value="Could Be Better"
                          v-model="msg.feedback"
                        />
                        Could Be Better
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="radio"
                          :name="'feedback-' + index"
                          value="A Lot to Improve"
                          v-model="msg.feedback"
                        />
                        A Lot to Improve
                      </label>
                    </li>
                  </ul>
                  <textarea
                    v-if="msg.feedback === 'Could Be Better' || msg.feedback === 'A Lot to Improve'"
                    v-model="msg.feedbackDetails"
                    placeholder="Let us know how we can improve..."
                  >
                  </textarea>
                  <button class="submit-feedback">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form @submit.prevent="connectAndSend" class="chat-form">
          <textarea id="prompt" v-model="prompt" placeholder="Type your message..." required></textarea>
          <button :class="{ 'small-button': true, connecting: isConnecting }" type="submit" :disabled="isConnecting">
            {{ isConnecting ? 'Please Wait...' : 'Send' }}
          </button>
        </form>
      </div>
    </div>

    <!-- Tabs -->
    <div class="tabs">
      <button :class="{ active: selectedTab === 'FAQ' }" @click="selectedTab = 'FAQ'" class="tab-button">
        <InlineSvg :src="IconFaq" class="icon" />FAQ
      </button>
      <button :class="{ active: selectedTab === 'HELP' }" @click="selectedTab = 'HELP'" class="tab-button">
        <InlineSvg :src="IconHelp" class="icon" />Help
      </button>
      <button
        :class="{ active: selectedTab === 'ASK MATTHEW' }"
        @click="selectedTab = 'ASK MATTHEW'"
        class="tab-button"
      >
        <InlineSvg :src="IconAskMathew" class="icon" />Ask Matthew
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import '@/styles/askmathew.css'
import FaqWindow from '@/components/Global/FaqWindow.vue'
import IconFaq from '@/assets/svg/icon-faq.svg'
import IconHelp from '@/assets/svg/icon-help.svg'
import IconAskMathew from '@/assets/svg/icon-ask-mathew.svg'
import IconCopy from '@/assets/svg/icon-copy.svg'
import IconLike from '@/assets/svg/icon-like.svg'

import HelpWindow from '@/components/Global/HelpWindow.vue'

export default {
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  components: {
    FaqWindow,
    HelpWindow,
  },
  setup(props) {
    const prompt = ref('')
    const isConnecting = ref(false)
    const selectedTab = ref('ASK MATTHEW')
    const chatHistory = ref([])
    let socket = null
    const copiedIndex = ref(null)
    const isFullscreen = ref(false)
    const connectAndSend = () => {
      if (!prompt.value) return
      const userId = props.userProfile.firstName
      const wsUrl = `wss://rh8gj8m97k.execute-api.us-east-1.amazonaws.com/prod/?userId=${userId}`

      if (!socket || socket.readyState !== WebSocket.OPEN) {
        isConnecting.value = true
        socket = new WebSocket(wsUrl)

        socket.onopen = () => {
          console.log('WebSocket connection established.')
          sendMessage()
        }

        socket.onmessage = (event) => {
          console.log('Message received:', event.data)
          const formattedMessage = formatMessage(event.data)
          chatHistory.value.push({
            sender: 'bot',
            text: formattedMessage,
            timestamp: getFormattedTime(),
            showFeedbackForm: true,
          })
          isConnecting.value = false
        }

        socket.onerror = () => {
          isConnecting.value = false
        }

        socket.onclose = () => {
          console.log('WebSocket connection closed.')
          isConnecting.value = false
        }
      } else {
        sendMessage()
      }
    }

    const sendMessage = () => {
      chatHistory.value.push({ sender: 'user', text: prompt.value, timestamp: getFormattedTime() })
      socket.send(JSON.stringify({ userId: props.userProfile.firstName, action: 'get_reply', prompt: prompt.value }))
      prompt.value = ''
    }

    const getFormattedTime = () => {
      const now = new Date()
      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      return `${hours}:${minutes}`
    }
    function formatPlainText(message) {
      let parsedMessage

      // Parse JSON if needed
      try {
        const messageObj = JSON.parse(message)
        parsedMessage = messageObj.message || message
      } catch (e) {
        parsedMessage = message
      }
      const plainTextMessage = parsedMessage
        .replace(/\\\$/g, '$') // Replace escaped dollar signs
        .replace(/\\n/g, '\n') // Replace escaped newlines with actual newlines
        .replace(/\*\*(.*?)\*\*/g, '$1') // Remove bold formatting
        .replace(/^\s*-\s+/gm, '') // Remove hyphens for list items
        .split('\n')
        .map((line) => line.trim()) // Trim each line
        .filter((line) => line.length > 0) // Remove empty lines
        .join('\n') // Join lines with actual newlines

      return plainTextMessage
    }

    function formatMessage(message) {
      let parsedMessage

      // Parse JSON if needed
      try {
        const messageObj = JSON.parse(message)
        parsedMessage = messageObj.message || message
      } catch (e) {
        parsedMessage = message
      }
      const formattedMessage = parsedMessage
        .replace(/\\\$/g, '$') // Replace escaped dollar signs
        .replace(/\\n/g, '<br>') // Convert escaped newlines to <br> for UI rendering
        .replace(/^\s*-\s+/gm, '<li>') // Detect and format list items starting with a hyphen
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Convert **bold** text
        .split('\n')
        .map((line) => line.trim()) // Trim each line
        .filter((line) => line.length > 0) // Remove empty lines
        .map((line) => {
          if (line.startsWith('<li>')) {
            return `${line}</li>` // Close list items
          } else {
            return `<p>${line}</p>` // Wrap non-list items in paragraphs
          }
        })
        .join('')
      return `<ul>${formattedMessage}</ul>`
    }

    const handleCopy = (text, index) => {
      const plainText = formatPlainText(text)

      navigator.clipboard
        .writeText(plainText)
        .then(() => {
          copiedIndex.value = index // Set the copied index
          setTimeout(() => {
            copiedIndex.value = null // Reset after 3 seconds
          }, 3000) // Set for 3 seconds
        })
        .catch((err) => {
          console.error('Failed to copy text:', err)
        })
    }

    return {
      prompt,
      isConnecting,
      selectedTab,
      chatHistory,
      connectAndSend,
      formatMessage,
      copiedIndex,
      handleCopy,
      IconFaq,
      IconHelp,
      IconAskMathew,
      IconCopy,
      IconLike,
      isFullscreen,
    }
  },
}
</script>
