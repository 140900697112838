<template>
  <div class="flex pb-5 items-center">
    <div class="mx-2">
      <BackButton v-if="withBackBtn" text="Back" @click="$router.go(-1)" />
    </div>
    <div class="flex justify-center w-11/12">
      <span class="text-sm sm:text-title text-primary font-semibold text-center">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'SwdSubHeader',
  props: {
    title: {
      type: String,
      require: true,
      default: 'header',
    },
  },
  setup(props) {
    const router = useRouter()

    const back = () => {
      router.push({ name: props.backPage })
    }

    return {
      back,
    }
  },
}
</script>
